<div class="mat-tab-header-pagination mat-tab-header-pagination-before mat-elevation-z4"
     #previousPaginator
     aria-hidden="true"
     mat-ripple [matRippleDisabled]="_disableScrollBefore || disableRipple"
     [class.mat-tab-header-pagination-disabled]="_disableScrollBefore"
     (click)="_handlePaginatorClick('before')"
     (mousedown)="_handlePaginatorPress('before', $event)"
     (touchend)="_stopInterval()">
  <div class="mat-tab-header-pagination-chevron"></div>
</div>

<div class="mat-tab-link-container" #tabListContainer (keydown)="_handleKeydown($event)">
  <div
    class="mat-tab-list"
    [class._mat-animation-noopable]="_animationMode === 'NoopAnimations'"
    #tabList
    (cdkObserveContent)="_onContentChanges()">
    <div class="mat-tab-links">
      <ng-content></ng-content>
    </div>
    <mat-ink-bar></mat-ink-bar>
  </div>
</div>

<div class="mat-tab-header-pagination mat-tab-header-pagination-after mat-elevation-z4"
     #nextPaginator
     aria-hidden="true"
     mat-ripple [matRippleDisabled]="_disableScrollAfter || disableRipple"
     [class.mat-tab-header-pagination-disabled]="_disableScrollAfter"
     (mousedown)="_handlePaginatorPress('after', $event)"
     (click)="_handlePaginatorClick('after')"
     (touchend)="_stopInterval()">
  <div class="mat-tab-header-pagination-chevron"></div>
</div>
