<div class="mat-step" *ngFor="let step of steps; let i = index; let isLast = last">
  <mat-step-header class="mat-vertical-stepper-header"
                   (click)="step.select()"
                   (keydown)="_onKeydown($event)"
                   [tabIndex]="_getFocusIndex() == i ? 0 : -1"
                   [id]="_getStepLabelId(i)"
                   [attr.aria-posinset]="i + 1"
                   [attr.aria-setsize]="steps.length"
                   [attr.aria-controls]="_getStepContentId(i)"
                   [attr.aria-selected]="selectedIndex === i"
                   [attr.aria-label]="step.ariaLabel || null"
                   [attr.aria-labelledby]="(!step.ariaLabel && step.ariaLabelledby) ? step.ariaLabelledby : null"
                   [index]="i"
                   [state]="_getIndicatorType(i, step.state)"
                   [label]="step.stepLabel || step.label"
                   [selected]="selectedIndex === i"
                   [active]="step.completed || selectedIndex === i || !linear"
                   [optional]="step.optional"
                   [errorMessage]="step.errorMessage"
                   [iconOverrides]="_iconOverrides"
                   [disableRipple]="disableRipple">
  </mat-step-header>

  <div class="mat-vertical-content-container" [class.mat-stepper-vertical-line]="!isLast">
    <div class="mat-vertical-stepper-content" role="tabpanel"
         [@stepTransition]="_getAnimationDirection(i)"
         (@stepTransition.done)="_animationDone.next($event)"
         [id]="_getStepContentId(i)"
         [attr.aria-labelledby]="_getStepLabelId(i)"
         [attr.aria-expanded]="selectedIndex === i">
      <div class="mat-vertical-content">
        <ng-container [ngTemplateOutlet]="step.content"></ng-container>
      </div>
    </div>
  </div>
</div>
