<div class="mat-horizontal-stepper-header-container">
  <ng-container *ngFor="let step of steps; let i = index; let isLast = last">
    <mat-step-header class="mat-horizontal-stepper-header"
                     (click)="step.select()"
                     (keydown)="_onKeydown($event)"
                     [tabIndex]="_getFocusIndex() === i ? 0 : -1"
                     [id]="_getStepLabelId(i)"
                     [attr.aria-posinset]="i + 1"
                     [attr.aria-setsize]="steps.length"
                     [attr.aria-controls]="_getStepContentId(i)"
                     [attr.aria-selected]="selectedIndex == i"
                     [attr.aria-label]="step.ariaLabel || null"
                     [attr.aria-labelledby]="(!step.ariaLabel && step.ariaLabelledby) ? step.ariaLabelledby : null"
                     [index]="i"
                     [state]="_getIndicatorType(i, step.state)"
                     [label]="step.stepLabel || step.label"
                     [selected]="selectedIndex === i"
                     [active]="step.completed || selectedIndex === i || !linear"
                     [optional]="step.optional"
                     [errorMessage]="step.errorMessage"
                     [iconOverrides]="_iconOverrides"
                     [disableRipple]="disableRipple">
    </mat-step-header>
    <div *ngIf="!isLast" class="mat-stepper-horizontal-line"></div>
  </ng-container>
</div>

<div class="mat-horizontal-content-container">
  <div *ngFor="let step of steps; let i = index"
       class="mat-horizontal-stepper-content" role="tabpanel"
       [@stepTransition]="_getAnimationDirection(i)"
       (@stepTransition.done)="_animationDone.next($event)"
       [id]="_getStepContentId(i)"
       [attr.aria-labelledby]="_getStepLabelId(i)"
       [attr.aria-expanded]="selectedIndex === i">
    <ng-container [ngTemplateOutlet]="step.content"></ng-container>
  </div>
</div>
